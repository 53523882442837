<template id="virtual-schedule">
  <v-row
    :align="$vuetify.breakpoint.mobile ? null : 'center'"
    style="min-height: 100vh"
    justify="center"
    class="fill-height"
  >
    <v-progress-circular
      indeterminate
      size="45"
      color="primary"
      :class="{ 'mt-8': $vuetify.breakpoint.mobile }"
      v-if="loading"
    ></v-progress-circular>
    <v-col cols="12" sm="5" v-else>
      <v-alert
        text
        outlined
        color="deep-orange"
        icon="mdi-alert-circle"
        v-if="errorMsg"
      >
        {{ errorMsg }}
      </v-alert>
      <v-card v-else class="mx-auto">
        <v-card-text class="text--primary">
          <v-row align="center" no-gutters justify="center" class="mt-2">
            <img
              style="max-height: 100px; max-width: 100px"
              alt="Logo"
              class="ui centered image client-logo"
              :src="organization.logo"
            />
          </v-row>

          <v-row align="center" no-gutters justify="center" class="my-4">
            <div class="text-h6 text-center">AVISO DE AGENDAMENTO VIRTUAL</div>
          </v-row>

          <v-row no-gutters>
            <v-col cols="12" class="mt-2">
              <div class="font-weight-medium">Data</div>
              <div>{{ card.aex_data }}</div>
            </v-col>
            <v-col cols="12" class="mt-2">
              <div class="font-weight-medium">Local do atendimento</div>
              <div>
                <a
                  :href="`http://maps.google.com/?q=${card.emp_end}`"
                  target="_blank"
                  title="Abrir mapa"
                >
                  {{ card.emp_end }}
                </a>
              </div>
            </v-col>
            <v-col cols="12" class="mt-2">
              <div class="font-weight-medium">Paciente</div>
              <div>{{ card.pac_nome }}</div>
            </v-col>
            <v-col cols="12" class="mt-2">
              <div class="font-weight-medium">Convênio</div>
              <div>{{ card.con_nomefant }}</div>
            </v-col>
            <v-col cols="12" class="mt-2">
              <div class="font-weight-medium">{{ doctorLabel }}</div>
              <div>{{ doctorName }}</div>
            </v-col>
            <v-col cols="12" class="mt-2">
              <div class="text-h6">Exames(s):</div>
              <v-divider class="my-2" />
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Horário</th>
                      <th class="text-left">Exame</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <template v-for="(exam, index) in card.exames">
                      <tr :key="`exam-${index}-tr-1`">
                        <td>{{ exam.hora | hour }}</td>
                        <td>
                          {{
                            exam.complemento
                              ? `${exam.produto} (${exam.complemento})`
                              : exam.produto
                          }}
                        </td>
                        <td>
                          <v-btn
                            text
                            v-show="exam.preparo"
                            @click.prevent="toggleOpen(index, exam)"
                          >
                            PREPARO
                            <v-icon>
                              {{
                                exam.open
                                  ? 'mdi-chevron-up'
                                  : 'mdi-chevron-down'
                              }}
                            </v-icon>
                          </v-btn>
                        </td>
                      </tr>
                      <tr :key="`exam-${index}-tr-2`" v-show="exam.open">
                        <td colspan="3" v-html="escape(exam.preparo)"></td>
                      </tr>
                    </template>
                  </tbody>
                </template>
              </v-simple-table>
              <br />
              <div v-html="customText"></div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import Api from '../resources/NajaSocialApi'
import { mapState } from 'vuex'

export default {
  name: 'bussiness_card',
  computed: {
    ...mapState({
      organization: state => state.data.organization,
    }),

    doctorLabel() {
      let text = ''
      if (this.card.tipoagenda == 'Consulta') {
        text = 'Médico'
      }
      if (this.card.tipoagenda == 'Exame') {
        text = 'Solicitante'
      }
      return text
    },

    doctorName() {
      let text = ''
      if (this.card.tipoagenda == 'Consulta') {
        text = this.card.med_nomeagenda
      }
      if (this.card.tipoagenda == 'Exame') {
        text = this.card.med_nomesolicitante
      }
      return text
    },
  },

  data() {
    return {
      quizzes: [],
      errorMsg: null,
      card: {
        exames: [],
      },
      loading: false,
      showError: false,
      checked: false,
      customText: '',
    }
  },

  created() {
    this.fetchData()
  },

  filters: {
    hour: function (value) {
      if (!value) return ''
      value = value.toString()
      return `${value.substring(0, 2)}:${value.substring(2, 4)}`
    },
  },

  methods: {
    toggleOpen(index, exam) {
      exam.open = !exam.open
      this.$set(this.card.exames, index, exam)
    },

    escape(src) {
      if (!src) {
        return ''
      }
      return src.replaceAll('"', '\\"')
    },

    fetchData() {
      this.loading = true

      Api.virtualSchedule(this.$route.params.token)
        .then(response => {
          this.card = response.data.schedule
          this.customText = response.data.custom_text
        })
        .catch(error => {
          if (error.response) {
            if (error.response.data.error) {
              this.errorMsg = error.response.data.error
            } else {
              this.errorMsg = 'Agendamento não encontrado'
            }
          } else {
            throw error
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>
